import FileSaver from 'file-saver';

export const AcBase64ToArrayBuffer = (data) => {
	var bString = window.atob(data);
	var bLength = bString.length;
	var bytes = new Uint8Array(bLength);
	for (var i = 0; i < bLength; i++) {
		var ascii = bString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
};

export const AcDownloadFile = (data, filename, mime) => {
	const blob = new Blob([data], { type: mime || 'application/octet-stream' });

	return FileSaver.saveAs(blob, filename);
};

export default AcDownloadFile;
