// Imports => Utilities
import { AcIsSet } from '@utils';

// Easing
if (!AcIsSet(Math.easeInOutQuad)) {
	Math.easeInOutQuad = (t, b, c, d) => {
		t /= d / 2;
		if (t < 1) return (c / 2) * t * t + b;
		t--;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	};
}

export const AcScrollTo = ($scroller, $target, offset = 0, duration = 1000) => {
	return new Promise((resolve) => {
		let AcScrollToTimer = null;

		if (AcIsSet($target?.scrollIntoView)) {
			const yOffset = -130;
			const y = $target.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}
		resolve();
	});
};

export default AcScrollTo;
