import loadable from '@loadable/component';

// Imports => Views
// Authentication
const AcForgotPassword = loadable(() =>
	import('@views/ac-forgot-password/ac-forgot-password')
);
const AcLogin = loadable(() => import('@views/ac-login/ac-login'));
const AcRegister = loadable(() => import('@views/ac-register/ac-register'));
const AcResetPassword = loadable(() =>
	import('@views/ac-reset-password/ac-reset-password')
);

// Impersonating
const AcImpersonate = loadable(() => import('@views/ac-impersonate/ac-impersonate'));

// 404 - Not Found
const AcNotFound = loadable(() => import('@views/ac-not-found/ac-not-found'));

// Documents
const AcDocuments = loadable(() => import('@views/ac-documents/ac-documents'));

// Faq
const AcFaq = loadable(() => import('@views/ac-faq/ac-faq'));

// Home
const AcHome = loadable(() => import('@views/ac-home/ac-home'));

// News
const AcNewsOverview = loadable(() =>
	import('@views/ac-news-overview/ac-news-overview')
);
const AcNewsDetail = loadable(() => import('@views/ac-news-detail/ac-news-detail'));

// Portfolio
const AcProjectOverview = loadable(() =>
	import('@views/ac-project-overview/ac-project-overview')
);
const AcProjectDetail = loadable(() =>
	import('@views/ac-project-detail/ac-project-detail')
);

// Profile
const AcProfile = loadable(() => import('@views/ac-profile/ac-profile'));

export {
	AcDocuments,
	AcFaq,
	AcForgotPassword,
	AcHome,
	AcImpersonate,
	AcLogin,
	AcNewsDetail,
	AcNewsOverview,
	AcNotFound,
	AcProfile,
	AcProjectDetail,
	AcProjectOverview,
	AcRegister,
	AcResetPassword,
};
