// Imports => Config
import config from '@config';
import { getLocale } from '@config/locale';

// Imports => Constants
import { TITLES } from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsEmptyString } from '@utils';

export const AcSetDocumentTitle = (title) => {
	const locale = getLocale();
	let result = TITLES.BASE;

	if (locale === 'en' || locale === 'en-GB') result = TITLES.BASE_EN;

	if (
		AcIsSet(config) &&
		AcIsSet(config.appName) &&
		!AcIsEmptyString(config.appName)
	) {
		result = config.appName;
	}

	if (AcIsSet(title)) {
		result = `${title} - ${result}`;
	}

	document.title = result;
};

export const AcGetDocumentTitle = (title) => {
	const locale = getLocale();
	let result = TITLES.BASE;

	if (locale === 'en') result = TITLES.BASE_EN;

	if (
		AcIsSet(config) &&
		AcIsSet(config.appName) &&
		!AcIsEmptyString(config.appName)
	) {
		result = config.appName;
	}

	if (AcIsSet(title)) {
		result = `${title} - ${result}`;
	}

	return result;
};

export default AcSetDocumentTitle;
