// @ts-ignore
String.prototype.hashCode = function () {
	var hash = 0,
		i,
		chr;
	if (this.length === 0) return hash;
	for (i = 0; i < this.length; i++) {
		chr = this.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

const cleanupExpiredCachedEntries = (cache, expires_in, now) => {
	Object.keys(cache).forEach((key) => {
		if (cache[key].timestamp + expires_in <= now) {
			// console.log('[Memoize] Expired!', key)
			delete cache[key];
		}
	});

	// console.log('[Memoize] Validated Cache', Object.keys(cache))
};

export const AcMemoize = (func, expires_in = 10 * 60) => {
	// expires_in is in seconds
	const cache = {};

	return (...args) => {
		// @ts-ignore
		const argsKey = JSON.stringify(args).hashCode();
		const now = Date.now();

		cleanupExpiredCachedEntries(cache, expires_in, now);

		if (argsKey in cache && cache[argsKey].timestamp + expires_in > now) {
			// Cached version still valid
			// console.log('[memoize] Cached version!', argsKey)
		} else {
			// Fresh version or expired
			// console.log('[memoize] Fresh version!', argsKey)
			cache[argsKey] = {
				value: func(...args),
				timestamp: Date.now(),
			};
		}

		return cache[argsKey].value;
	};
};
