// Imports => DayJS
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

require('dayjs/locale/nl');
require('dayjs/locale/en');

// Imports => Config
import { getLocale, setLocale } from '@config/locale';

export const AcFormatDate = (input, from = 'DD-MM-YYYY', to = 'DD MMM Y') => {
  const locale = getLocale();

  dayjs.locale(locale);
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);
  dayjs.extend(customParseFormat);

  // Verify valid date / dayjs object
  if (from && !dayjs(input, from)) {
    return input;
  } else if (!dayjs(input)) {
    return input;
  }

  // Format input
  const output = from ? dayjs(input, from).format(to) : dayjs(input).format(to);

  return output;
};

export const AcGetTimeDifference = (
  time,
  now = dayjs(),
  format = null,
  ms = false
) => {
  const locale = getLocale();

  dayjs.locale(locale);
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);
  dayjs.extend(customParseFormat);

  const start = dayjs(now).isValid() ? dayjs(now) : dayjs();
  const end = format ? dayjs(time, format) : dayjs(time);
  const diff = dayjs.duration(start.diff(end));

  if (ms) return diff.asMilliseconds();
  return diff;
};

export const AcGetDaysDifference = (date, now = dayjs()) => {
  const locale = getLocale();

  dayjs.locale(locale);
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);
  dayjs.extend(customParseFormat);

  return dayjs(date).diff(now, 'days');
};

export const AcGetTimeUntilNow = (date, now = dayjs()) => {
  const locale = getLocale();

  dayjs.locale(locale);
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);

  return dayjs(date).fromNow();
};

export const sortDatesDesc = (firstDate, secondDate) => {
  const locale = getLocale();

  dayjs.locale(locale);
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);
  dayjs.extend(customParseFormat);

  return dayjs(firstDate, 'YYYY-MM-DD HH:mm').isAfter(
    dayjs(secondDate, 'YYYY-MM-DD HH:mm')
  )
    ? -1
    : 1;
};

export default AcFormatDate;
